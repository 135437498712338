@use 'src/styles/uikit/variables';
@import '~normalize.css';

html {
  //scroll-behavior: smooth;
  line-height: 1.15;
}

body {
  &.modal-open {
    padding: 0 variables.$spacer * 1.6 0 0;
    overflow: hidden;
  }

  overflow-y: scroll;
}

a {
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: variables.$spacer;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

button {
  backface-visibility: hidden;
}

hr {
  border-width: 1px;
  border-color: variables.$neutral_light-gray-1;
  border-style: none none solid;
}

img {
  max-width: 100%;
}
