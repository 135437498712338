@use 'variables';

.btn {
  display: inline-block;
  padding: calc(#{variables.$spacer} - 2px);
  font-family: map-get(variables.$text-weight, 'regular');
  font-size: map-get(variables.$text-sizes, 'h7');
  line-height: variables.$text-height * 1.2;
  color: variables.$log-print_text;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  border: 2px solid variables.$transparent;
  border-radius: map-get(variables.$border-radius, 'sm');
  outline: none;
  transition: all 0.2s;

  &:disabled {
    cursor: not-allowed;
    background: variables.$log-print_main-yellow-hover;
    border: 2px solid variables.$log-print_main-yellow-hover;
    opacity: 0.4;
  }

  &.sm {
    padding: calc(#{variables.$spacer} - 2px);
    font-size: map-get(variables.$text-sizes, 'h7');
    line-height: variables.$text-height * 1.2;
  }

  &.lg {
    padding: variables.$spacer variables.$spacer * 2;
    font-size: map-get(variables.$text-sizes, 'h5');
    line-height: variables.$text-height * 1.45;
  }

  &-style-1 {
    &.btn-inline {
      background: variables.$log-print_main-yellow;
      border: 2px solid variables.$log-print_main-yellow;

      &:hover:not(:disabled) {
        background: variables.$log-print_main-yellow-hover;
        border: 2px solid variables.$log-print_main-yellow-hover;
      }
    }

    &.btn-outline {
      background: variables.$transparent;
      border: 2px solid variables.$log-print_main-yellow;

      &:hover:not(:disabled) {
        background: rgba(variables.$log-print_main-yellow-hover, 0.05);
        border: 2px solid variables.$log-print_main-yellow-hover;
      }
    }
  }

  &-style-green {
    &.btn-inline {
      background: variables.$log-print_main-green;
      border: 2px solid variables.$log-print_main-green;

      &:hover:not(:disabled) {
        background: variables.$log-print_main-green-hover;
        border: 2px solid variables.$log-print_main-green-hover;
      }
    }

    &.btn-outline {
      background: variables.$transparent;
      border: 2px solid variables.$log-print_main-green;

      &:hover:not(:disabled) {
        background: rgba(variables.$log-print_main-green-hover, 0.05);
        border: 2px solid variables.$log-print_main-green-hover;
      }
    }
  }

  &-style-amber {
    color: white;

    &.btn-inline {
      background: variables.$log-print_main-amber;
      border: 2px solid variables.$log-print_main-amber;

      &:hover:not(:disabled) {
        background: variables.$log-print_main-amber-hover;
        border: 2px solid variables.$log-print_main-amber-hover;
      }
    }

    &.btn-outline {
      background: variables.$transparent;
      border: 2px solid variables.$log-print_main-amber;

      &:hover:not(:disabled) {
        background: rgba(variables.$log-print_main-amber-hover, 0.05);
        border: 2px solid variables.$log-print_main-amber-hover;
      }
    }
  }

  &-style-info {
    &.btn-inline {
      background: variables.$log-print_main-blue;
      border: 2px solid variables.$log-print_main-blue;
      color: white;

      &:hover:not(:disabled) {
        background: variables.$log-print_main-blue-hover;
        border: 2px solid variables.$log-print_main-blue-hover;
      }
    }

    &.btn-outline {
      background: variables.$transparent;
      border: 2px solid variables.$log-print_main-blue;

      &:hover:not(:disabled) {
        background: rgba(variables.$log-print_main-blue-hover, 0.05);
        border: 2px solid variables.$log-print_main-blue-hover;
      }
    }
  }

  &-style-2 {
    color: variables.$log-print_dark-gray;

    &.btn-inline {
      background: variables.$neutral_light-gray-1;
      border: 2px solid variables.$neutral_light-gray-1;

      &:hover:not(:disabled) {
        background: variables.$neutral_light-gray-4;
        border: 2px solid variables.$neutral_light-gray-4;
      }
    }

    &.btn-outline {
      background: variables.$transparent;
      border: 2px solid variables.$neutral_light-gray-4;

      &:hover:not(:disabled) {
        background: rgba(variables.$neutral_light-gray-4, 0.1);
        border: 2px solid variables.$neutral_light-gray-4;
      }
    }
  }

  &-style-3 {
    color: variables.$log-print_dark-gray;

    &.btn-inline {
      background: variables.$neutral_white;
      border: 1px solid variables.$log-print_main-yellow-hover;

      &:hover:not(:disabled) {
        background: variables.$neutral_white;
        border: 1px solid variables.$log-print_main-yellow-hover;
      }
    }
  }

  &-style-gray {
    color: variables.$log-print_dark-gray;

    &.btn-outline {
      background: variables.$neutral_white;
      border: 1px solid variables.$neutral_light-gray-1;

      &:hover:not(:disabled) {
        background: variables.$neutral_light-gray-1;
        border: 1px solid variables.$neutral_light-gray-4;
      }
    }
  }

  &-style-4 {
    font-family: map-get(variables.$text-weight, 'semibold');
    color: variables.$neutral_white;

    span {
      font-family: map-get(variables.$text-weight, 'semibold');
      color: variables.$neutral_white;
    }

    &.btn-inline {
      background: variables.$log-print_text;
      border: 2px solid variables.$log-print_text;

      &:hover:not(:disabled) {
        background: variables.$neutral_dark-2;
        border: 2px solid variables.$neutral_dark-2;
      }
    }
  }

  &-style-5 {
    color: white;
    &.btn-inline {
      background: variables.$log-print_main-red;
      border: 2px solid variables.$log-print_main-red;

      &:hover:not(:disabled) {
        background: variables.$log-print_main-red-hover;
        border: 2px solid variables.$log-print_main-red-hover;
      }
    }

    &.btn-outline {
      background: variables.$transparent;
      border: 2px solid variables.$log-print_main-red;

      &:hover:not(:disabled) {
        background: rgba(variables.$log-print_main-red-hover, 0.05);
        border: 2px solid variables.$log-print_main-red-hover;
      }
    }
  }
}
