@use 'variables';

@each $class, $px in variables.$border-radius {
  .rounded-#{$class} {
    border-radius: $px !important;
  }
}

.bordered {
  box-shadow: 0 1px 3px rgb(0 0 0 / 6%);
}

//.bordered-transparent {
//  border: 1px solid variables.$transparent;
//}
