@use 'variables';

ngx-file-drop {
  .ngx-file-drop__drop-zone {
    height: unset !important;
    margin: unset !important;
    border: unset !important;
  }

  .ngx-file-drop__drop-zone--over {
    background-color: unset !important;
  }

  .ngx-file-drop__content {
    display: unset !important;
    align-items: unset !important;
    justify-content: unset !important;
    height: unset !important;
    color: unset !important;
  }

  .ngx-file-drop__drop-zone-label {
    text-align: center;
  }

  .ngx-file-drop__file-input {
    display: none;
  }

  /* app theme */
  .ngx-file-drop__drop-zone {
    padding: variables.$spacer * 2 0 !important;
    border: 2px dashed variables.$neutral_light-gray-1 !important;
    border-radius: map-get(variables.$border-radius, 'md') !important;
    transition: all 0.2s !important;

    &:hover {
      border-color: variables.$neutral_gray-3 !important;
    }
  }

  .ngx-file-drop__drop-zone--over {
    border-color: variables.$log-print_main-red !important;
  }
}
