@use 'variables';

.custom-scrollbar {
  @media(hover: hover) and (pointer: fine) {
    $color-track: variables.$neutral_light-gray-1;
    $color-thumb: variables.$neutral_gray-2;

    scrollbar-color: $color-thumb $color-track;

    &::-webkit-scrollbar {
      width: 3px;
      height: 100%;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-track;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-thumb;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
      background: $color-track;
    }
  }
}

.opacity-h {
  opacity: 0.5;
}

.position-r {
  position: relative;
}

.visibility-h {
  visibility: hidden;
}

.overflow-h {
  overflow: hidden;
}

.cursor-p {
  cursor: pointer;
}

.cursor-d {
  cursor: default;
}

.pointer-n {
  pointer-events: none;
}

.select-none {
  user-select: none;
}

.hidden {
  display: none;
}