@use 'mixins';

// SIZES

//$breakpoints: (
//  'md': 1440px,
//  'sm': 1024px,
//  'xs': 768px
//);

// COLORS
$notifications-black: #2e2e2e;
//$notifications-highlight: #90232d;
$notifications-highlight-bg: #FFF3CD;
$notifications-highlight-fg: #654c03;
$notifications-highlight-close: #787C83;
$notifications-highlight-bg2: #D4EDDA;
$notifications-highlight-fg2: #155724;
$notifications-highlight-close2: #787C83;

$face-color-main: #f7b68b;
$face-color-shadows: #ec9568;

$neutral_dark: #000;
$neutral_dark-2: #242424;
$neutral_gray-1: #9fa8b8;
$neutral_gray-2: #8898b9;
$neutral_gray-3: #c7cdd8;
$neutral_gray-3-2: #69799b;
$neutral_gray-4: #474c54;
$neutral_light-gray-1: #e4e9f2;
$neutral_light-gray-2: #f7f8fb;
$neutral_light-gray-3: #F9F9F9;
$neutral_light-gray-4: #D8E1F1;
$neutral_light-gray-5: #F5F6F8;
$neutral_white: #fff;

$main_light-violet: #7d5dde;
$main_violet: #5555c7;
$main_light-selection: #cce;
$main_rose: #ff4081;
$main_dark-violet: #372277;

$UI_button_active: #5555c7;
$UI_button_hover: #337;
$UI_button_onclick: #8787f9;
$UI_button_disabled: #edf0f6;
$UI_input_infocus: #5555c7;

$actions_error: #ff6275;
$actions_success: #74cb8c;
$actions_attention: #ffb63d;

$log-print_dark-gray: #474c54;
$log-print_main-green: #61d87c;
$log-print_main-green-hover: #26cb48;
$log-print_main-blue: #4daabd;
$log-print_main-blue-hover: #2690a5;
$log-print_main-yellow: #ffb63d;
$log-print_main-yellow-hover: #FFA002;
/* TODO: https://caniuse.com/css-rrggbbaa */
$log-print_main-yellow-opacity: #ffb63d33;
$log-print_main-red: #fd3c4e;
$log-print_main-red-hover: #e93748;
$log-print_success: #61d87c;
/* TODO: https://caniuse.com/css-rrggbbaa */
$log-print_success-opacity: #61d87c33;
$log-print_success-2: #d6f2da;
$log-print_text: #000;
$log-print_light-blue: #c7e5f1;
$log-print_main-cyan: #10cec1;
$log-print_main-violet: #8f3888;
$log-print_light-yellow: #ffd99a;
$log-print_light-yellow-hover: #FFCF80;
$log-print_violet-dark-links: #5f265a;
$log-print_blue: #4dabbe;
$log-print_cream: #FFF3DE;
$log-print_rose: #FFF3F4;
$log-print_yellow-25: #FFF0D8;
$log-print_main-amber: #DD7D56;
$log-print_main-amber-hover: #dc521a;


$tooltip-bg: #5E5E60;
$tooltip-fg: #ffffff;

$transparent: transparent;

// BORDER

$border-radius: (
  'none': 0,
  'xs': 5px,
  'sm': 5px,
  'md': 10px,
  'lg': 15px,
);

// SPACE

$percentage: (25, 50, 75, 100);
$content: (max-content, min-content, fit-content);

$spacer: 0.625rem;
$spacer-x: $spacer;
$spacer-y: $spacer;

$spacers: (
  0: (x: 0, y: 0),
  h: (x: ($spacer-x * 0.5), y: ($spacer-y * 0.5)),
  1: (x: ($spacer-x * 1), y: ($spacer-y * 1)),
  2: (x: ($spacer-x * 1.5), y: ($spacer-y * 1.5)),
  3: (x: ($spacer-x * 2), y: ($spacer-y * 2)),
  4: (x: ($spacer-x * 2.5), y: ($spacer-y * 2.5)),
  5: (x: ($spacer-x * 3), y: ($spacer-y * 3)),
  6: (x: ($spacer-x * 4), y: ($spacer-y * 4)),
  7: (x: ($spacer-x * 5), y: ($spacer-y * 5)),
  8: (x: ($spacer-x * 6), y: ($spacer-y * 6)),
  9: (x: ($spacer-x * 7), y: ($spacer-y * 7)),
);

// DISPLAY

$display: none, inline, inline-block, block, flex;

// TYPOGRAPHY

$text-height: 1;
$text-heights: (
  '1': $text-height * 1.1,
  '2': $text-height * 1.2,
  '3': $text-height * 1.3,
  '4': $text-height * 1.4,
  '5': $text-height * 1.5,
);

$text-align: (left, center, right);
$text-decoration: (underline, none);
$text-transform: (uppercase, lowercase, capitalize);

$text-weight: (
  'light': ('Proxima Nova Light', sans-serif),
  'regular': ('Proxima Nova Regular', sans-serif),
  'semibold': ('Proxima Nova Semibold', sans-serif),
  'bold': ('Proxima Nova Bold', sans-serif)
);

$text-weight-decimal: (400, 600, 700);

$text-spacing: 1em;
$text-spacings: (
  '1': $text-spacing * 0.1,
  '2': $text-spacing * 0.2,
  '3': $text-spacing * 0.3,
  '4': $text-spacing * 0.4,
  '5': $text-spacing * 0.5,
);

$text-size: 1rem;
$text-sizes: (
  'h1': $text-size * 2.25,
  'h2': $text-size * 1.75,
  'h3': $text-size * 1.5,
  'h4': $text-size * 1.25,
  'h5': $text-size * 1.125,
  'h6': $text-size * 1,
  'h7': $text-size * 0.875,
  'h8': $text-size * 0.75
);

:root {
  --viewport: unset;
  --zoom: 1.0;
  --gap: #{$spacer * 4};
  --header-bar-height: 82px;
  --view-width: 1317px;
  //--content-width: 785px;
  --content-width: 1440px;
  --content-left-width: 160px;
  --left-bar-width: 230px;
  --right-bar-width: 285px;
}

@include mixins.apply-to('md') {
  :root {
    --viewport: md;
    --gap: #{$spacer * 3};
    //--content-width: 805px;
    --content-width: 100%;
    --header-bar-height: 47px;
    --left-bar-width: 60px;
    --view-width: 100%;
  }
}

@include mixins.apply-to('sm') {
  :root {
    --viewport: sm;
    --gap: #{$spacer * 2};
    //--content-width: 680px;
    --right-bar-width: 200px;
  }
}

@include mixins.apply-to('xs') {
  :root {
    --viewport: xs;
    --gap: #{$spacer * 1};
    --right-bar-width: 0;
    //--content-width: 520px;
    //--view-width: calc(--content-width - --left-bar-width - --right-bar-width);
  }
}
@include mixins.apply-to-retina2x('md') { :root {--viewport: md (dpr2x); --zoom: 0.65} }
@include mixins.apply-to-retina2x('sm') { :root {--viewport: sm (dpr2x); --zoom: 0.65} }
@include mixins.apply-to-retina2x('xs') { :root {--viewport: xs (dpr2x); --zoom: 0.65} }

@include mixins.apply-to-retina3x('md') { :root {--viewport: md (dpr3x); --zoom: 0.50} }
@include mixins.apply-to-retina3x('sm') { :root {--viewport: sm (dpr3x); --zoom: 0.50} }
@include mixins.apply-to-retina3x('xs') { :root {--viewport: xs (dpr3x); --zoom: 0.50} }

@include mixins.apply-to-retina4x('md') { :root {--viewport: md (dpr4x); --zoom: 0.50} }
@include mixins.apply-to-retina4x('sm') { :root {--viewport: sm (dpr4x); --zoom: 0.50} }
@include mixins.apply-to-retina4x('xs') { :root {--viewport: xs (dpr4x); --zoom: 0.50} }
