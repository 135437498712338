@use 'variables';
@use 'mixins';

body {
  overflow-x: hidden;

  &::-webkit-scrollbar-track
  {
    background-color: #EF342F;
    background-image: -webkit-linear-gradient(-45deg,
      rgba(255, 255, 255, .5) 12.5%,
      transparent 12.5%,
      transparent 25%,
      rgba(255, 255, 255, .5) 25%,
      rgba(255, 255, 255, .5) 37.5%,
      transparent 37.5%,
      transparent 50%,

      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, .5) 62.5%,
      transparent 62.5%,
      transparent 75%,
      rgba(255, 255, 255, .5) 75%,
      rgba(255, 255, 255, .5) 87.5%,
      transparent 87.5%,
      transparent,
    );
  }

  &::-webkit-scrollbar
  {
    width: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 1rem;
    background-color: #0E6B3E;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  background-color: #faf8f8;
}
//@media screen and (max-width: map-get(variables.$breakpoints, 'md')) {
//  body {
//    -width: map-get(variables.$breakpoints, 'md');
//  }
//}
//@media screen and (max-width: map-get(variables.$breakpoints, 'sm')) {
//  body {
//    -width: map-get(variables.$breakpoints, 'sm');
//  }
//}
//@media screen and (max-width: map-get(variables.$breakpoints, 'xs')) {
//  body {
//    -width: map-get(variables.$breakpoints, 'xs');
//  }
//}

@include mixins.apply-to('md') {
  body {-background-color: #FF0000}
}
@include mixins.apply-to-retina2x('md') {
  body {-background-color: #2b1866; zoom: var(--zoom);}
}
@include mixins.apply-to-retina3x('md') {
  body {-background-color: #00ff41; zoom: var(--zoom);}
}
@include mixins.apply-to-retina4x('md') {
  body {-background-color: #2d609a; zoom: var(--zoom);}
}

@include mixins.apply-to('sm') {
  body {-background-color: #6f3131}
}
@include mixins.apply-to-retina2x('sm') {
  body {-background-color: #685b8f; zoom: var(--zoom);}
}
@include mixins.apply-to-retina3x('sm') {
  body {-background-color: #212629; zoom: var(--zoom);}
}
@include mixins.apply-to-retina4x('sm') {
  body {-background-color: #008e13; zoom: var(--zoom);}
}

@include mixins.apply-to('xs') {
  body {
    -background-color: #000080;
    min-width: 670px;
    overflow-x: auto;
  }
}
@include mixins.apply-to-retina2x('xs') {
  body {-background-color: #8b158b; zoom: var(--zoom);}
}
@include mixins.apply-to-retina3x('xs') {
  body {-background-color: #30164a; zoom: var(--zoom);}
}
@include mixins.apply-to-retina4x('xs') {
  body {-background-color: #738e00; zoom: var(--zoom);}
}

body {
  //background-color: #F7F8FB;
  background-color: #faf8f8;
}

@each $prop in variables.$display {
  .d-#{$prop} {
    display: $prop;
    @if $prop == flex {
      &.fw-wrap {
        flex-wrap: wrap;
      }

      &.fd-column {
        flex-direction: column;
      }

      &.fd-row {
        flex-direction: row;
      }

      &.ai-stretch {
        align-items: stretch;
      }

      &.ai-start {
        align-items: flex-start;
      }

      &.ai-center {
        align-items: center;
      }

      &.ai-end {
        align-items: flex-end;
      }

      &.as-stretch {
        align-self: stretch;
      }

      &.as-start {
        align-self: flex-start;
      }

      &.as-center {
        align-self: center;
      }

      &.as-end {
        align-self: flex-end;
      }

      &.jc-start {
        justify-content: flex-start;
      }

      &.jc-center {
        justify-content: center;
      }

      &.jc-end {
        justify-content: flex-end;
      }

      &.jc-between {
        justify-content: space-between;
      }

      &.jc-around {
        justify-content: space-around;
      }

      &.jc-stretch {
        justify-content: stretch;
      }

      &.flex-1 {
        flex: 1;
      }
    }
  }
}
