@use 'variables';

$radio-square: map-get(variables.$text-sizes, 'h6') * 0.75;
$radio-square-bullet: map-get(variables.$text-sizes, 'h6') * 0.5;

$check-square: map-get(variables.$text-sizes, 'h6') * 1;
$check-square-bullet: map-get(variables.$text-sizes, 'h6');

@mixin inputIconBase($justify, $modify: 'md') {
  position: relative;

  input {
    //width: calc(100% - (#{variables.$spacer * 8} + 2px));
    width: 100%;

    @if $justify == start {
      padding: variables.$spacer * 1.5 variables.$spacer * 2 variables.$spacer * 1.5 variables.$spacer * 6;
    } @else {
      padding: variables.$spacer * 1.5 variables.$spacer * 6 variables.$spacer * 1.5 variables.$spacer * 2;
    }
  }

  app-svg-icon {
    position: absolute;
    //top: 0;
    bottom: 0;
    //width: variables.$spacer * 2.4;
    //height: variables.$spacer * 2.4;
    //padding: variables.$spacer * 2 variables.$spacer * 2;
    padding: variables.$spacer * 1.5 variables.$spacer * 1.5;
    fill: variables.$log-print_text;

    &:not(.password) {
      pointer-events: none;
    }

    &.password {
      pointer-events: all;
      cursor: pointer;
    }

    @if $justify == start {
      left: 0;
    } @else {
      right: 0;
    }
  }

  &.sm {
    input {
      //width: calc(100% - (#{variables.$spacer * 6.5} + 2px));
      width: 100%;

      @if $justify == start {
        padding: variables.$spacer * 1 variables.$spacer * 1.5 variables.$spacer * 1 variables.$spacer * 5;
      } @else {
        padding: variables.$spacer * 1 variables.$spacer * 5 variables.$spacer * 1 variables.$spacer * 1.5;
      }
    }

    app-svg-icon {
      width: variables.$spacer * 2;
      height: variables.$spacer * 2;
      padding: variables.$spacer * 1 variables.$spacer * 1.5;
    }
  }
}

@font-face {
  font-family: 'Password Mask';
  font-style: normal;
  font-weight: 400;
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA) format('woff');
}

input[type=password]:not(:placeholder-shown) {
  font-family: 'Password Mask', sans-serif;
  letter-spacing: 2px;
}

fieldset:disabled {
  .iti {
    color: variables.$neutral_gray-2;
    cursor: not-allowed;
    background: variables.$neutral_light-gray-2;
    border-color: variables.$neutral_light-gray-1;

    .iti__flag-container {
      pointer-events: none;

      .iti__selected-flag {
        .iti__flag {
          opacity: 0.5;
        }

        .iti__selected-dial-code {}

        .iti__arrow {
          opacity: 0.5;
        }
      }
    }
  }
}

.iti {
  &.iti--show-flags {
    width: 100%;

    .iti__flag-container {
      left: 0;
      right: unset;

      .iti__selected-flag {
        padding: 0 variables.$spacer 0 variables.$spacer;
        background: variables.$neutral_light-gray-2;
        border-right: 1px solid variables.$neutral_light-gray-1;
        border-top-left-radius: map-get(variables.$border-radius, 'sm');
        border-bottom-left-radius: map-get(variables.$border-radius, 'sm');

        .iti__flag {}

        .iti__selected-dial-code {
          margin-top: 2px;
          margin-left: 10px;
          font-family: map-get(variables.$text-weight, 'regular');
          font-size: map-get(variables.$text-sizes, 'h4');
          line-height: variables.$text-height * 1.1;
        }

        .iti__arrow {
          width: 8px;
          height: 8px;

          border: 2px solid variables.$log-print_dark-gray;
          border-right-color: transparent;
          border-bottom-color: transparent;

          transform: rotate(-135deg) translateY(2px);

          &.iti__arrow--up {
            transform: rotate(45deg) translateY(-2px);
          }
        }
      }
    }

    input {
      display: inline-block;
      //width: calc(100% - 2px);
      width: 100%;
      min-height: 54px;
      max-height: 22px;
      padding: variables.$spacer * 1.5 variables.$spacer * 2 variables.$spacer * 1.5 variables.$spacer * 8;
      overflow: hidden;
      font-family: map-get(variables.$text-weight, 'regular');
      font-size: map-get(variables.$text-sizes, 'h4');
      line-height: variables.$text-height * 1.1;
      color: variables.$log-print_text;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      background: variables.$neutral_white;
      border: 1px solid variables.$neutral_light-gray-1;
      border-radius: map-get(variables.$border-radius, 'sm');
      outline: none;
      transition: border 0.2s;
      appearance: none;

      &::placeholder {
        font-size: map-get(variables.$text-sizes, 'h6');
        color: variables.$neutral_gray-2;
      }

      &:focus {
        border: 1px solid variables.$log-print_main-yellow;
      }

      &:disabled {
        color: variables.$neutral_gray-2;
        cursor: not-allowed;
        background: variables.$neutral_light-gray-2;
        border-color: variables.$neutral_light-gray-1;
      }

      &:invalid,
      &.ng-touched.ng-invalid {
        border-color: variables.$actions_error !important;
      }
    }
  }
}

.input {
  display: flex;
  flex-direction: column;

  > label {
    font-size: variables.$text-size * 0.75;
  }

  > input {
    display: inline-block;
    //width: calc(100% - (#{variables.$spacer * 4} + 2px));
    width: 100%;
    //max-height: 22px;
    padding: variables.$spacer * 1.5 variables.$spacer * 2;
    overflow: hidden;
    font-family: map-get(variables.$text-weight, 'regular');
    font-size: map-get(variables.$text-sizes, 'h4');
    line-height: variables.$text-height * 1.1;
    color: variables.$log-print_text;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    background: variables.$neutral_white;
    border: 1px solid variables.$neutral_light-gray-1;
    border-radius: map-get(variables.$border-radius, 'sm');
    outline: none;
    transition: border 0.2s;
    appearance: none;

    &::placeholder {
      font-size: map-get(variables.$text-sizes, 'h6');
      color: variables.$neutral_gray-2;
    }

    &:focus {
      border: 1px solid variables.$log-print_main-yellow;
    }

    &:disabled {
      color: variables.$neutral_gray-2;
      cursor: not-allowed;
      background: variables.$neutral_light-gray-2;
      border-color: variables.$neutral_light-gray-1;
    }

    &:invalid,
    &.ng-touched.ng-invalid {
      border-color: variables.$actions_error !important;
    }
  }

  > .hint {
    span {
      font-size: variables.$text-size * 0.75;
    }
  }

  > .error {
    span {
      font-size: variables.$text-size * 0.75;
    }
  }

  &.i-start {
    @include inputIconBase(start);
  }

  &.i-end {
    @include inputIconBase(end);
  }

  &.sm {
    > input {
      padding: variables.$spacer * 1 variables.$spacer * 1.5;
      font-size: map-get(variables.$text-sizes, 'h7');
      line-height: variables.$text-height * 1.3;

      &::placeholder {
        font-size: map-get(variables.$text-sizes, 'h7');
        line-height: variables.$text-height * 1.3;
        color: variables.$neutral_gray-2;
      }
    }
  }
}

.textarea {
  display: flex;
  flex-direction: column;

  > label {
    font-size: variables.$text-size * 0.75;
  }

  > textarea {
    //width: calc(100% - (#{variables.$spacer * 4} + 2px));
    width: 100%;
    padding: variables.$spacer * 2;
    font-family: map-get(variables.$text-weight, 'regular');
    font-size: map-get(variables.$text-sizes, 'h6');
    line-height: variables.$text-height * 1.1;
    color: variables.$log-print_text;
    resize: none;
    background: variables.$neutral_white;
    border: 1px solid variables.$neutral_light-gray-1;
    border-radius: map-get(variables.$border-radius, 'sm');
    outline: none;
    transition: all 0.2s, height 0ms;
    appearance: none;

    &::placeholder {
      font-size: map-get(variables.$text-sizes, 'h6');
      color: variables.$neutral_gray-2;
    }

    &:focus {
      border: 1px solid variables.$log-print_main-yellow;
    }

    &:disabled {
      color: variables.$neutral_gray-2;
      cursor: not-allowed;
      background: variables.$neutral_light-gray-2;
      border-color: variables.$neutral_light-gray-1;
    }

    &:invalid,
    &.ng-touched.ng-invalid {
      border-color: variables.$actions_error !important;
    }
  }

  > .hint {
    span {
      font-size: variables.$text-size * 0.75;
    }
  }

  > .error {
    span {
      font-size: variables.$text-size * 0.75;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;

  > input {
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;

    + label {
      display: inline-flex;
      font-size: map-get(variables.$text-sizes, 'h7');
      line-height: variables.$text-height * 1.45;
      color: variables.$neutral_gray-2;
      cursor: pointer;
      user-select: none;
    }

    + label::before {
      width: $check-square;
      min-width: $check-square;
      height: $check-square;
      min-height: $check-square;
      margin: 0 variables.$spacer * 1 0 0;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: $check-square-bullet;
      border: 2px solid variables.$neutral_light-gray-1;
      border-radius: map-get(variables.$border-radius, 'md') * 0.5;
      transition: border 0.2s;
    }

    &:not(:disabled):not(:checked) + label:hover::before {
      background-color: variables.$neutral_light-gray-2;
    }

    &:checked + label {
      .label {
        font-weight: map-get(variables.$text-weight, 'bold');
      }

      &::before {
        background: url('../../assets/icons/svg/green-check.svg') no-repeat center/ 10px 10px;
      }
    }

    &:disabled + label {
      cursor: not-allowed;
      opacity: 0.5;

      &::before {
        background-color: variables.$neutral_light-gray-1;
        border-color: variables.$neutral_light-gray-1;
      }
    }
  }
}
