@font-face {
  font-family: "Proxima Nova Bold";
  src: url("ProximaNova/ProximaNova-Bold.eot");
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
    url("ProximaNova/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova/ProximaNova-Bold.woff2") format("woff2"),
    url("ProximaNova/ProximaNova-Bold.woff") format("woff"),
    url("ProximaNova/ProximaNova-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Proxima Nova Light";
  src: url("ProximaNova/ProximaNova-Light.eot");
  src: local("Proxima Nova Light"), local("ProximaNova-Light"),
    url("ProximaNova/ProximaNova-Light.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova/ProximaNova-Light.woff2") format("woff2"),
    url("ProximaNova/ProximaNova-Light.woff") format("woff"),
    url("ProximaNova/ProximaNova-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("ProximaNova/ProximaNova-Semibold.eot");
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
    url("ProximaNova/ProximaNova-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("ProximaNova/ProximaNova-Semibold.woff2") format("woff2"),
    url("ProximaNova/ProximaNova-Semibold.woff") format("woff"),
    url("ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Proxima Nova Regular";
  src: url("ProximaNova/ProximaNova-Regular.eot");
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
    url("ProximaNova/ProximaNova-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("ProximaNova/ProximaNova-Regular.woff2") format("woff2"),
    url("ProximaNova/ProximaNova-Regular.woff") format("woff"),
    url("ProximaNova/ProximaNova-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
