@layer components {
  /* Gray DP */

  .gray-00dp {
    box-shadow: 0px 1px 1px rgba(64, 71, 95, 0.08);
  }

  .gray-01dp {
    box-shadow: 0px 1px 1px rgba(64, 71, 95, 0.04), 0px 2px 1px rgba(64, 71, 95, 0.04), 0px 1px 3px rgba(64, 71, 95, 0.08);
  }

  .gray-02dp {
    box-shadow: 0px 2px 2px rgba(64, 71, 95, 0.14), 0px 3px 1px rgba(64, 71, 95, 0.12), 0px 1px 5px rgba(64, 71, 95, 0.2);
  }

  .gray-03dp {
    box-shadow: 0px 3px 4px rgba(64, 71, 95, 0.14), 0px 3px 3px rgba(64, 71, 95, 0.12), 0px 1px 8px rgba(64, 71, 95, 0.2);
  }

  .gray-04dp {
    box-shadow: 0px 4px 5px rgba(64, 71, 95, 0.14), 0px 1px 10px rgba(64, 71, 95, 0.12), 0px 2px 4px rgba(64, 71, 95, 0.2);
  }

  .gray-06dp {
    box-shadow: 0px 6px 10px rgba(64, 71, 95, 0.14), 0px 1px 18px rgba(64, 71, 95, 0.12), 0px 3px 5px rgba(64, 71, 95, 0.2);
  }

  .gray-08dp {
    box-shadow: 0px 8px 10px rgba(64, 71, 95, 0.14), 0px 3px 14px rgba(64, 71, 95, 0.12), 0px 5px 5px rgba(64, 71, 95, 0.2);
  }

  .gray-09dp {
    box-shadow: 0px 9px 12px rgba(64, 71, 95, 0.14), 0px 3px 16px rgba(64, 71, 95, 0.12), 0px 5px 6px rgba(64, 71, 95, 0.2);
  }

  .gray-12dp {
    box-shadow: 0px 12px 17px rgba(64, 71, 95, 0.14), 0px 5px 22px rgba(64, 71, 95, 0.12), 0px 7px 8px rgba(64, 71, 95, 0.2);
  }

  .gray-16dp {
    box-shadow: 0px 12px 17px rgba(64, 71, 95, 0.14), 0px 5px 22px rgba(64, 71, 95, 0.12), 0px 7px 8px rgba(64, 71, 95, 0.2);
  }

  .gray-24dp {
    box-shadow: 0px 24px 38px rgba(64, 71, 95, 0.15), 0px 9px 46px rgba(64, 71, 95, 0.12), 0px 11px 15px rgba(64, 71, 95, 0.2);
  }

  /* Primary DP */

  .primary-09dp {
    box-shadow: 0px 9px 12px rgba(30, 88, 255, 0.07), 0px 3px 16px rgba(30, 88, 255, 0.06), 0px 5px 6px rgba(30, 88, 255, 0.1);
  }

  .primary-24dp {
    box-shadow: 0px 24px 38px rgba(30, 88, 255, 0.07), 0px 9px 46px rgba(30, 88, 255, 0.06), 0px 11px 15px rgba(30, 88, 255, 0.1);
  }

  /* Secondary DP */

  .secondary-24dp {
    box-shadow: 0px 24px 38px rgba(255, 112, 147, 0.07), 0px 9px 46px rgba(255, 112, 147, 0.06), 0px 11px 15px rgba(255, 112, 147, 0.1);
  }
}
