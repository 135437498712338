@charset "utf-8";

/* https://tailwindcss.com/ */

@tailwind base;
@tailwind components;
@tailwind variants;
@tailwind utilities;

// UI-KIT

@import 'styles/uikit/variables';
@import 'styles/uikit/colors';
@import 'styles/uikit/typography';
@import 'styles/uikit/borders';
@import 'styles/uikit/buttons';
@import 'styles/uikit/display';
@import 'styles/uikit/inputs';
//@import 'styles/uikit/spacing';
@import 'styles/uikit/utilities';

/* BASE */

@import "styles/base/shadows";
//@import "styles/base/typography";

/* COMPONENTS */

//@import "styles/components/buttons";
//@import "styles/components/overlay";

/* EXTEND */

@import 'styles/vendor/ng2-date-picker';
@import 'styles/vendor/ngx-charts';
@import 'styles/vendor/ngx-file-drop';
@import 'styles/vendor/normalize';
@import 'styles/vendor/lenis';

/* BREAKING CHANGES https://github.com/vlio20/angular-datepicker/blob/master/CHANGELOG.md */

//@import '~@angular/cdk/overlay-prebuilt.css';
