@use 'variables';

$colors: (
  'color-neutral_dark': variables.$neutral_dark,
  'color-neutral_white': variables.$neutral_white,
  'color-neutral_light-gray-1': variables.$neutral_light-gray-1,
  'color-neutral_light-gray-2': variables.$neutral_light-gray-2,
  'color-neutral_light-gray-3': variables.$neutral_light-gray-3,
  'color-log-print_main-violet': variables.$log-print_main-violet,
  'color-neutral_gray-1': variables.$neutral_gray-1,
  'color-neutral_gray-2': variables.$neutral_gray-2,
  'color-neutral_gray-3': variables.$neutral_gray-3-2,
  'color-neutral_gray-4': variables.$neutral_gray-4,
  'color-log-print_text': variables.$log-print_text,
  'color-log-print_dark-gray': variables.$log-print_dark-gray,
  'color-log-print_violet-dark-links': variables.$log-print_violet-dark-links,
  'color-log-print_blue': variables.$log-print_blue,
  'color-log-print_light-blue': variables.$log-print_light-blue,
  'color-log-print_main-red': variables.$log-print_main-red,
  'color-log-print_success': variables.$log-print_success,
  'color-log-print_success2': variables.$notifications-highlight-fg2,
  'color-log-print_main-yellow': variables.$log-print_main-yellow,
  'color-log-print_light-yellow': variables.$log-print-light-yellow,
  'color-actions_error': variables.$actions_error,
  'color-log-print_cream': variables.$log-print_cream,
  'color-log-print_rose': variables.$log-print_rose,
  'color-log-print_yellow-25': variables.$log-print_yellow-25
);

@each $color, $hex in $colors {
  @each $class, $prop in (bg: background, text: color) {
    .#{$class}-#{$color} {
      #{$prop}: $hex !important;
    }
  }
}

@each $color, $hex in (
  'color-neutral_dark': variables.$neutral_dark,
  'color-neutral_white': variables.$neutral_white,
  'color-log-print_success': variables.$log-print_success,
  'color-log-print_main-red': variables.$log-print_main-red
) {
  .fill-#{$color} {
    fill: $hex !important;
  }
}
