@use 'variables';
@use 'sass:math';

ngx-charts-chart {
  text {
    fill: variables.$neutral_gray-2;
  }
}

ngx-tooltip-content {
  span.tooltip-caret {
    display: none;
  }
}

.ngx-chart-tooltip {
  --ngx-tooltip-color-background: variables.$neutral_dark-2;
  --ngx-tooltip-color-font: variables.$neutral_white;

  position: relative;
  margin: 0 -#{variables.$spacer * 2};
  font-family: map-get(variables.$text-weight, 'regular');
  font-size: map-get(variables.$text-sizes, 'h7');
  color: var(--ngx-tooltip-color-font);
  background: var(--ngx-tooltip-color-background);
  border-radius: map-get(variables.$border-radius, 'sm');
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);

  &::before {
    position: absolute;
    bottom: -#{math.div(variables.$spacer, 2)};
    left: 50%;
    z-index: 10000;
    width: variables.$spacer * 1.2;
    height: variables.$spacer * 1.2;
    content: '';
    background: var(--ngx-tooltip-color-background);
    transform: translateX(-50%) rotate(45deg);
  }

  hr {
    border-color: var(--ngx-tooltip-color-font);
    border-style: solid;
    border-top: 1px;
    border-bottom: 0;
    opacity: 0.25;
  }

  .count {
    font-family: map-get(variables.$text-weight, 'semibold');
  }
}

.ngx-chart-tooltip-series {
  position: relative;
  z-index: 10000;
  margin: 0 -#{variables.$spacer * 2};
  font-family: map-get(variables.$text-weight, 'regular');
  font-size: map-get(variables.$text-sizes, 'h7');
  color: variables.$neutral_dark-2;
  background: variables.$neutral_white;
  border: 1px solid variables.$neutral_light-gray-1;
  border-radius: map-get(variables.$border-radius, 'sm');

  .color {
    width: variables.$spacer;
    height: variables.$spacer;
    border-radius: variables.$spacer;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: -#{math.div(variables.$spacer, 2.5)};
    z-index: 10000;
    width: variables.$spacer * 0.5;
    height: variables.$spacer * 0.5;
    content: '';
    background: variables.$neutral_white;
    border-bottom: 1px solid variables.$neutral_light-gray-1;
    border-left: 1px solid variables.$neutral_light-gray-1;
    transform: translateY(-50%) rotate(45deg);
  }

  .count {
    font-family: map-get(variables.$text-weight, 'semibold');
  }
}
