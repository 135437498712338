@use 'variables';

.gx-node:not(:empty) {
  //font-kerning: none;
}
.gx-line:not(:empty) {
  display: block;
}
.gx-word:not(:empty) {
  display: inline-block;
}

*:not(svg, .gx-node, .gx-line, .gx-word) {
  font-family: 'Proxima Nova Regular', sans-serif;
}

@each $class, $size in variables.$text-sizes {
  .#{$class} {
    font-size: $size !important;
  }

  #{$class} {
    font-family: 'Proxima Nova Bold', sans-serif;
    font-size: $size !important;
  }
}

@each $class, $weight in variables.$text-weight {
  .text-#{$class} {
    font-family: $weight;
  }
}

@each $weight in variables.$text-weight-decimal {
  .text-weight-#{$weight} {
    font-weight: $weight;
  }
}

@each $align in variables.$text-align {
  .text-#{$align} {
    text-align: $align;
  }
}

@each $decoration in variables.$text-decoration {
  .text-d-#{$decoration} {
    text-decoration: $decoration;
  }
}

@each $transform in variables.$text-transform {
  .text-#{$transform} {
    text-transform: $transform;
  }
}

@each $class, $prop in variables.$text-heights {
  .line-height-#{$class} {
    line-height: $prop;
  }
}

@each $class, $prop in variables.$text-spacings {
  .letter-spacing-#{$class} {
    letter-spacing: $prop;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.text-prewrap {
  white-space: pre-wrap !important;
}

.text-initial-uppercase::first-letter {
  text-transform: uppercase;
}

.text-initial-lowercase::first-letter {
  text-transform: lowercase;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
