@use 'variables';

$cell-size: 40px;

.dp-popup {
  padding: variables.$spacer * 0.5;
  border: 1px solid variables.$neutral_light-gray-1;
  border-radius: map-get(variables.$border-radius, 'sm');
  box-shadow: none !important;
  transform: translateY(variables.$spacer);

  .dp-calendar-nav-container {
    margin-bottom: variables.$spacer;
    border: none;
  }

  .dp-nav-header-btn {
    padding: variables.$spacer * 0.3525 variables.$spacer;
    line-height: 1;
    border: none;
    border-radius: map-get(variables.$border-radius, 'sm');
  }

  .dp-nav-btns-container {
    > div > button {
      width: unset;
      background: none;
      border: none;
    }
  }

  .dp-weekdays {
    padding: 0 0 variables.$spacer * 0.5 0;
    border-bottom: 1px solid variables.$neutral_light-gray-1;

    span {
      font-family: map-get(variables.$text-weight, 'semibold');
      font-size: map-get(variables.$text-sizes, 'h7');
      text-transform: uppercase;

      &:nth-last-child(-n+2) {
        color: variables.$actions_error;
      }
    }
  }

  .dp-calendar-week {
    button:nth-last-child(-n+2) {
      color: variables.$actions_error;
    }
  }

  .dp-calendar-weekday {
    width: $cell-size;
    font-size: map-get(variables.$text-sizes, 'h6');
    border: none;
  }

  .dp-calendar-wrapper {
    border: none;
  }

  .dp-calendar-day, .dp-calendar-month {
    background: none;
    border: none;

    &.dp-selected {
      color: variables.$neutral_dark-2 !important;
      background: variables.$log-print_main-yellow;
    }
  }

  .dp-calendar-day {
    width: $cell-size;
    height: $cell-size;
    border-radius: 50%;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .dp-calendar-month {
    width: 70px;
    height: 30px;
    margin: 10px 0;
    font-size: map-get(variables.$text-sizes, 'h4');
    border-radius: map-get(variables.$border-radius, 'md');

    &:disabled {
      cursor: not-allowed;
    }
  }
}
